












import Vue from 'vue';
export default Vue.extend({
  name: 'NumberCircle',
  data: () => ({}),
  props: {
    number: [String, Number],
    value: [String, Number],
    readonly: [Boolean],
  },
});
