


































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import API from '@/plugins/axios';
import { Field, FieldTypes, Question, Questionnaire } from '@/types/types';
import NumberCircle from '@/components/NumberCircle.vue';

export default Vue.extend({
  components: { NumberCircle },
  name: 'Questions',
  data: () => ({
    loaded: false,
    questions: [] as Question[],
    finished: false,
    isCompleted: false,
    questionerName: '',
    isDemographic: false,
    loadingNext: false,
    FieldTypes,
  }),
  watch: {
    questions: {
      deep: true,
      immediate: true,
      // eslint-disable-next-line prettier-vue/prettier
      handler: function(val) {
        this?.$store.dispatch('updateQuestionsState', { questionsLength: val.length });
      },
    },
    finished: {
      immediate: true,
      // eslint-disable-next-line prettier-vue/prettier
      handler: function(val) {
        this?.$store.dispatch('updateQuestionsState', { finished: val });
      },
    },
    isCompleted: {
      immediate: true,
      // eslint-disable-next-line prettier-vue/prettier
      handler: function(val) {
        this?.$store.dispatch('updateQuestionsState', { isCompleted: val });
      },
    },
  },
  computed: {
    ...mapGetters(['questionNumber']),
    valueSelected() {
      return !!this.questions[this.questionNumber].fields.filter(f => f.required).every(f => f.answer_fields.length);
    },
    bgColor(): string {
      return this.$store.state.User.settings.common.background_color || 'default';
    },
  },
  methods: {
    fieldTextValue(textValue: string, n: number) {
      const parsedValue = JSON.parse(textValue)[0];
      if (parsedValue) return parsedValue[n];
      return '';
    },
    updateValueField({ id }: Field, event: number | string, string?: boolean) {
      if (this.isCompleted) return;
      const field = this.questions[this.questionNumber].fields.find(f => f.id == id);
      if (field) {
        if (field.answer_fields.length) {
          if (string) {
            field.answer_fields[0].text_value = event as string;
          } else {
            field.answer_fields[0].value = event as number;
          }
        } else {
          if (string) {
            field.answer_fields = [
              {
                // eslint-disable-next-line prettier-vue/prettier
                value: (null as unknown) as number,
                text_value: event as string,
              },
            ];
          } else {
            field.answer_fields = [
              {
                value: event as number,
                text_value: '',
              },
            ];
          }
        }
      }
    },
    async handleNext(changeQuestion = true) {
      if (this.loadingNext) return;
      this.loadingNext = true;
      if (!this.isCompleted && this.valueSelected) {
        const answers = this.questions[this.questionNumber].fields
          .map(({ id, answer_fields }) => {
            if (answer_fields.length) {
              if (answer_fields[0].text_value) {
                return {
                  value: answer_fields[0].value,
                  text_value: answer_fields[0].text_value,
                  question_field_id: id,
                };
              } else {
                return {
                  value: answer_fields[0].value,
                  question_field_id: id,
                };
              }
            } else {
              return null;
            }
          })
          .filter(f => !!f);
        // 5
        await API.post(`v1/prosys/answers/`, {
          question: this.questions[this.questionNumber].id,
          evaluation: this.$route.params.qid,
          fields: answers,
        });
      }
      if (changeQuestion) {
        this?.$store.dispatch('updateQuestionsState', {
          questionNumber: this.questionNumber + 1,
        });
      }
      this.loadingNext = false;
    },
    async finish() {
      if (this.isCompleted) {
        this.$router.push('/');
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await (this as any).handleNext(false);
      // 5
      await API.put(`v1/prosys/evaluations/finish/${this.$route.params.qid}/`);
      this.finished = true;
    },
    onSpace(e: KeyboardEvent) {
      if (e.code !== 'Space' || !e.ctrlKey) return;

      if (this.questions.length > this.questionNumber + 1) {
        e.preventDefault();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any).handleNext(this.valueSelected);
      }
    },
    isPopulated(error: string) {
      return (v: string) => !!`${v}`.length || error;
    },
    isNumber(error: string) {
      return (v: string) => /^\d+$/.test(v) || error;
    },
    isDate(error: string) {
      return (v: string) => /^\d{1,2}\.\d{1,2}\.\d{4}$/.test(v) || error;
    },
    isValidDate(error: string) {
      return (v: string) =>
        (this as any)
          .$date(`${v.substring(3, 5)}.${v.substring(0, 2)}.${v.substring(6)}`)
          .isBetween((this as any).$date().subtract(150, 'year'), (this as any).$date().subtract(10, 'year')) || error;
    },
    fieldRules(field: Field) {
      const rules = [];
      if (field.required) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        rules.push((this as any).isPopulated(this.$i18n.t('rules.required')));
      }
      if (field.numeric) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        rules.push((this as any).isNumber(this.$i18n.t('rules.number')));
      }
      if (field.label.includes('dd.mm.yyyy')) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        rules.push((this as any).isDate(this.$i18n.t('rules.date')));
        rules.push((this as any).isValidDate(this.$i18n.t('rules.invalid_date')));
      }
      return rules;
    },
    showField(field: Field) {
      if (field.dependant_field_id) {
        const dependantField = this.questions[this.questionNumber].fields.find(f => f.id == field.dependant_field_id);
        if (dependantField) {
          if (dependantField.answer_fields.length) {
            if (dependantField.answer_fields[0].value == (field.show_if === 'Yes' ? 0 : 1)) {
              return true;
            }
          }
        }
      } else {
        return true;
      }
      return false;
    },
  },
  async mounted() {
    this?.$store.dispatch('updateQuestionsState', { questionNumber: 0 });
    // 5
    const { data } = await API.get(`v1/prosys/questions/${this.$route.params.qid}/`);
    this.questions = data;
    this.loaded = true;
    const questionWithoutAnswer = this.questions.find(
      questioner => !questioner.fields.some(f => f.answer_fields.length)
    );
    const { data: questionnaires } = await API.get('v1/prosys/questioners/');
    const q = (questionnaires as Questionnaire[]).find(
      questioner => questioner.evaluation.length && questioner.evaluation[0].id == Number(this.$route.params.qid)
    );
    if (q) {
      this.isCompleted = q.evaluation[0].is_completed;
      this.questionerName = this.$i18n.locale === 'mt' ? q.maltese_title : q.title;
      this.isDemographic = q.demographic;
    }
    if (questionWithoutAnswer) {
      const index = this.questions.indexOf(questionWithoutAnswer);
      this?.$store.dispatch('updateQuestionsState', { questionNumber: index });
      if (index == 0) {
        this.$snackbar({
          icon: 'mdi-information',
          text: this.$t('Questions.snackbarTest'),
          type: 'info',
        });
      }
    } else if (!this.isCompleted) {
      this?.$store.dispatch('updateQuestionsState', {
        questionNumber: this.questions.length - 1,
      });
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    document.addEventListener('keydown', (this as any).onSpace);
    this.$snackbar({
      icon: 'mdi-information',
      text: this.$t('Questions.snackbarSpace'),
      type: 'info',
    });
  },
  beforeDestroy() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    document.removeEventListener('keydown', (this as any).onSpace);
  },
});
