













































import Vue from 'vue';
import { mapGetters } from 'vuex';
export default Vue.extend({
  name: 'LayoutHeader',
  data: () => ({
    closing: false,
  }),
  computed: {
    ...mapGetters(['questionsState']),
    bgColor(): string {
      return this.$store.state.User.settings.common.background_color || 'default';
    },
  },
  methods: {
    updateQuestionNumber() {
      this?.$store.dispatch('updateQuestionsState', { questionNumber: this.questionsState.questionNumber - 1 });
    },
  },
});
